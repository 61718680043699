// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_loader__0th\\+D {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.styles_loader_spinner__hREEF {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3275c3;
  border-radius: 50%;
  animation: styles_spin__uInAQ 1s linear infinite;
}

@keyframes styles_spin__uInAQ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loadable/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gDAAA;AADF;;AAIA;EACE;IACE,uBAAA;EADF;EAIA;IACE,yBAAA;EAFF;AACF","sourcesContent":["@import '../../styles/theme.scss';\r\n\r\n.loader {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 70vh;\r\n}\r\n\r\n.loader_spinner {\r\n  width: 40px;\r\n  height: 40px;\r\n  border: 4px solid #f3f3f3;\r\n  border-top: 4px solid $primary;\r\n  border-radius: 50%;\r\n  animation: spin 1s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `styles_loader__0th+D`,
	"loader_spinner": `styles_loader_spinner__hREEF`,
	"spin": `styles_spin__uInAQ`
};
export default ___CSS_LOADER_EXPORT___;
