import React from "react";
import { Link } from "react-router-dom";
import routeNames from "../../routes/routeNames";
import "./SidebarStyle.css";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const user = useSelector((state: any) => state?.user?.user);
  const userRole = user?.role;

  if (!user || !userRole) {
    return null;
  }

  const isSuperAdmin = userRole.name === "Super Admin";
  const accessiblePages = userRole?.accessiblePages || [];

  return (
    <div className="sidebar">
      <ul>
        {(isSuperAdmin || accessiblePages.includes("dashboard")) && (
          <li>
            <Link to={routeNames.landing}>Dashboard</Link>
          </li>
        )}
        {(isSuperAdmin || accessiblePages.includes("users")) && (
          <li>
            <Link to={routeNames.users}>Users</Link>
          </li>
        )}
        {(isSuperAdmin || accessiblePages.includes("subscriptions")) && (
          <li>
            <Link to={routeNames.subscriptions}>Subscriptions</Link>
          </li>
        )}
        {(isSuperAdmin || accessiblePages.includes("banks")) && (
          <li>
            <Link to={routeNames.banks}>Banks</Link>
          </li>
        )}
        {(isSuperAdmin || accessiblePages.includes("analytics")) && (
          <li>
            <Link to={routeNames.analytics}>Analytics</Link>
          </li>
        )}
        {(isSuperAdmin || accessiblePages.includes("roles")) && (
          <li>
            <Link to={routeNames.roles}>Role Management</Link>
          </li>
        )}
        {(isSuperAdmin || accessiblePages.includes("devices")) && (
          <li>
            <Link to={routeNames.devices}>Device Whitelisting</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
