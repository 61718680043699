//
import { useAppSelector } from "../store/hooks";
import GeneralRoutes from "./general";
import UserRoutes from "./user";

const Routes = () => {
  // hooks
  const { user } = useAppSelector((state) => state.user);
  console.log("user data in Routes is: " + user);
  // return
  if (user?.token) {
    console.log("Token is present, opening user routes: " + user);
    return <UserRoutes />;
  } else {
    console.log("Token is not present, opening general routes");
    return <GeneralRoutes />;
  }
};

export default Routes;
