// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_footer__s4fH0 {
  display: flex;
  flex-direction: row;
  background: rgba(50, 117, 195, 0.7);
  padding: 24px;
  color: white;
  gap: 135px;
}
.styles_footer__s4fH0 img {
  width: 50px;
}

@media screen and (max-width: 991.98px) {
  .styles_footer__s4fH0 {
    gap: 60px;
    padding: 60px 40px;
  }
}
@media screen and (max-width: 576.98px) {
  .styles_footer__s4fH0 {
    padding: 30px 24px;
    gap: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,mCCJU;EDKV,aAAA;EACA,YAAA;EACA,UAAA;AADF;AAGE;EACE,WAAA;AADJ;;AAKA;EACE;IACE,SAAA;IACA,kBAAA;EAFF;AACF;AAKA;EACE;IACE,kBAAA;IACA,SAAA;EAHF;AACF","sourcesContent":["@import '../../styles/theme.scss';\r\n\r\n.footer {\r\n  display: flex;\r\n  flex-direction: row;\r\n  background: $header-bg;\r\n  padding: 24px;\r\n  color: white;\r\n  gap: 135px;\r\n\r\n  img {\r\n    width: 50px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 991.98px) {\r\n  .footer {\r\n    gap: 60px;\r\n    padding: 60px 40px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 576.98px) {\r\n  .footer {\r\n    padding: 30px 24px;\r\n    gap: 24px;\r\n  }\r\n}","$primary: #3275c3;\r\n$header-bg: rgb(50, 117, 195, 0.7);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styles_footer__s4fH0`
};
export default ___CSS_LOADER_EXPORT___;
