// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__FkQix {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.styles_wrapper__FkQix .styles_outlet__pc6-g {
  max-width: 100%;
  min-width: 100%;
  flex: content;
  min-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/AuthLayout/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAGE;EACE,eAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AADJ","sourcesContent":["@import '../../styles/theme.scss';\r\n\r\n.wrapper {\r\n  position: relative;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  .outlet {\r\n    max-width: 100%;\r\n    min-width: 100%;\r\n    flex: content;\r\n    min-height: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__FkQix`,
	"outlet": `styles_outlet__pc6-g`
};
export default ___CSS_LOADER_EXPORT___;
