import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import "../../styles/DeviceWhitelisting.css";
import DeviceWhitelistingModal from "./DeviceWhitelistingModal";
import ConfirmationModal from "./DeleteModal";

export interface Device {
  id: string;
  deviceBrand: string;
  deviceModel: string;
  isActive: boolean;
  source: string;
  lastModified: Date;
}

interface DeviceGroup {
  [brand: string]: Device[];
}

const PAGE_SIZE = 10;

const DeviceWhitelisting = () => {
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup>({});
  const [filteredGroups, setFilteredGroups] = useState<DeviceGroup>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState<{ [brand: string]: number }>(
    {}
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [item, setItem] = useState<Device | null>(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [editingDevice, setEditingDevice] = useState<Device | null>(null);
  const [openBrands, setOpenBrands] = useState<Set<string>>(new Set());
  const [openMenu, setOpenMenu] = useState<string | null>(null); // Track currently open menu

  const toggleMenu = (brand: string, index: number) => {
    const key = `${brand}-${index}`;
    setOpenMenu((prev) => (prev === key ? null : key)); // Toggle between open and closed
  };

  const handleDelete = (device: Device) => {
    setOpenMenu(null); // Close any open menu
    setOpenDeleteModal(true);
    setItem(device);
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData(searchTerm);
  }, [searchTerm]);

  const fetchData = async (searchTerm: string) => {
    setIsDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}devicewhitelisting/devices`
      );
      const data: DeviceGroup = await response.json();

      if (searchTerm) {
        // Apply search filter
        const searchLower = searchTerm.toLowerCase();
        const filtered: DeviceGroup = Object.entries(data).reduce(
          (acc, [brand, devices]) => {
            const brandMatch = brand.toLowerCase().includes(searchLower);
            const modelMatches = devices.filter((device: Device) =>
              device.deviceModel.toLowerCase().includes(searchLower)
            );
            if (brandMatch || modelMatches.length > 0) {
              acc[brand] = modelMatches.length > 0 ? modelMatches : devices;
            }
            return acc;
          },
          {} as DeviceGroup
        );

        setFilteredGroups(filtered);
      } else {
        // If no search term, show all data
        setFilteredGroups(data);
      }

      setDeviceGroups(data);
      setCurrentPage({}); // Reset pagination when new data is fetched
    } catch (error) {
      console.error("Failed to fetch devices data", error);
    } finally {
      setIsDataLoading(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const toggleBrandCollapse = (brand: string) => {
    const newOpenBrands = new Set(openBrands);
    if (newOpenBrands.has(brand)) {
      newOpenBrands.delete(brand);
    } else {
      newOpenBrands.add(brand);
    }
    setOpenBrands(newOpenBrands);
  };

  const handlePageChange = (brand: string, pageNumber: number) => {
    setCurrentPage({ ...currentPage, [brand]: pageNumber });
  };

  const getPaginatedData = (brand: string, data: Device[]) => {
    const startIndex = ((currentPage[brand] || 1) - 1) * PAGE_SIZE;
    return data.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const handleEditClick = (device: Device) => {
    setEditingDevice(device);
    setModalOpen(true);
    setOpenMenu(null); // Close any open menu
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="header">
          <h1>Device Whitelisting</h1>
        </div>
        <Card className="card-body">
          <div className="search-bar-container">
            <Input
              type="text"
              placeholder="Search by brand or model"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-bar"
            />
            <Button
              onClick={() => setModalOpen(true)}
              color="primary"
              className="ml-2"
            >
              Add Device
            </Button>
          </div>
          <CardBody>
            <div className="brand-list">
              {isDataLoading ? (
                <div className="text-center">Loading...</div>
              ) : Object.keys(filteredGroups).length > 0 ? (
                Object.entries(filteredGroups).map(([brand, devices]) => (
                  <Card key={brand} className="mb-3">
                    <CardHeader
                      onClick={() => toggleBrandCollapse(brand)}
                      className="brand-header"
                      style={{
                        display: "flex",
                        gap: "14px",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "800px",
                          fontSize: "15px",
                        }}
                      >
                        <i className="fa-solid fa-arrow-down"></i>
                      </p>
                      {brand.charAt(0).toUpperCase() + brand.slice(1)}
                    </CardHeader>
                    <Collapse isOpen={openBrands.has(brand)}>
                      <CardBody>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Device Model</th>
                              <th>Active Status</th>
                              <th>Source</th>
                              <th>Last Updated</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getPaginatedData(brand, devices).map(
                              (device, index) => (
                                <tr key={device.id}>
                                  <td>{device.deviceModel}</td>
                                  <td>
                                    {device.isActive ? "Active" : "Inactive"}
                                  </td>
                                  <td>{device.source}</td>
                                  <td>
                                    {new Date(
                                      device.lastModified
                                    ).toLocaleString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </td>
                                  <td>
                                    <div className="action-menu">
                                      <p
                                        onClick={() => toggleMenu(brand, index)}
                                        className="action-menu-button"
                                        style={{
                                          fontWeight: "800px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <i className="fa fa-ellipsis-v"></i>
                                      </p>
                                      {openMenu === `${brand}-${index}` && (
                                        <div className="action-menu-content">
                                          <p
                                            onClick={() =>
                                              handleEditClick(device)
                                            }
                                          >
                                            Edit
                                          </p>
                                          <p
                                            onClick={() => handleDelete(device)}
                                          >
                                            Delete
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        {devices.length > PAGE_SIZE && (
                          <div className="pagination-container">
                            <Pagination>
                              <PaginationItem
                                disabled={(currentPage[brand] || 1) === 1}
                              >
                                <PaginationLink
                                  previous
                                  onClick={() =>
                                    handlePageChange(
                                      brand,
                                      (currentPage[brand] || 1) - 1
                                    )
                                  }
                                />
                              </PaginationItem>
                              {[
                                ...Array(Math.ceil(devices.length / PAGE_SIZE)),
                              ].map((_, index) => (
                                <PaginationItem
                                  key={index}
                                  active={
                                    index + 1 === (currentPage[brand] || 1)
                                  }
                                >
                                  <PaginationLink
                                    onClick={() =>
                                      handlePageChange(brand, index + 1)
                                    }
                                  >
                                    {index + 1}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                              <PaginationItem
                                disabled={
                                  (currentPage[brand] || 1) ===
                                  Math.ceil(devices.length / PAGE_SIZE)
                                }
                              >
                                <PaginationLink
                                  next
                                  onClick={() =>
                                    handlePageChange(
                                      brand,
                                      (currentPage[brand] || 1) + 1
                                    )
                                  }
                                />
                              </PaginationItem>
                            </Pagination>
                          </div>
                        )}
                      </CardBody>
                    </Collapse>
                  </Card>
                ))
              ) : (
                <div className="text-center">No devices found</div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
      {modalOpen && (
        <DeviceWhitelistingModal
          showDialog={modalOpen}
          onCloseDialog={() => {
            setModalOpen(false);
            setEditingDevice(null);
            fetchData(searchTerm);
          }}
          editingDevice={editingDevice}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          showDialog={openDeleteModal}
          onCloseDialog={() => {
            setOpenDeleteModal(false);
            setItem(null);
            fetchData(searchTerm);
          }}
          itemName={item}
          setItem={setItem}
        />
      )}
    </div>
  );
};

export default DeviceWhitelisting;
