import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoute = ({ allowedPages }: { allowedPages: string[] }) => {
  const location = useLocation();
  const userRole = useSelector((state: any) => state?.user?.user?.role);

  // If the user's role is Super Admin, grant access to all routes
  if (userRole?.name === "Super Admin") {
    return <Outlet />;
  }

  const accessiblePages = userRole?.accessiblePages || [];
  const hasAccess = allowedPages.some((page) => accessiblePages.includes(page));

  if (!hasAccess) {
    // Find the first accessible route
    const accessibleRoutes = [
      { path: "/users", permission: "users" },
      { path: "/banks", permission: "banks" },
      { path: "/subscriptions", permission: "subscriptions" },
      { path: "/analytics", permission: "analytics" },
      { path: "/roles", permission: "roles" },
      { path: "/devices", permission: "devices" },
    ];

    const firstAccessibleRoute =
      accessibleRoutes.find((route) =>
        accessiblePages.includes(route.permission)
      )?.path || "/";

    return <Navigate to={firstAccessibleRoute} state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
