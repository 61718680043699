import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import "../../styles/global.scss";
import "../../styles/BankTable.css";
import { postRequest, putRequest } from "../../helpers/apiHelper";
import { Device } from ".";
import { useAppSelector } from "../../store/hooks";

interface Modal {
  showDialog: boolean;
  onCloseDialog: () => void;
  editingDevice: Device | null;
}

const DeviceSchema = Yup.object().shape({
  deviceBrand: Yup.string().required("Device Brand is required*"),
  deviceModel: Yup.string()
    .min(2, "Device Model is too short")
    .max(50, "Device Model is too long")
    .required("Device Model is required*"),
  isActive: Yup.string().required("Active status is required*"),
});

const deviceBrandOptions = [
  { value: "Samsung", label: "Samsung" },
  { value: "Huawei", label: "Huawei" },
  { value: "Xiaomi", label: "Xiaomi" },
  { value: "Apple", label: "Apple" },
  { value: "Vivo", label: "Vivo" },
  { value: "Realme", label: "Realme" },
  { value: "Infinix", label: "Infinix" },
  { value: "Tecno", label: "Tecno" },
  { value: "OPPO", label: "OPPO" },
  { value: "Redmi", label: "Redmi" },
  { value: "Google", label: "Google" },
  { value: "OnePlus", label: "OnePlus" },
  { value: "HTC", label: "HTC" },
];

const DeviceWhitelistingModal = ({
  showDialog,
  onCloseDialog,
  editingDevice,
}: Modal) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { user } = useAppSelector((state) => state.user);
  const token = user?.token;

  // Create or update device based on editingDevice
  //   const saveDevice = async (deviceData: Device) => {
  //     try {
  //       setLoading(true);
  //       setError(null);

  //       const headers: HeadersInit = new Headers();
  //       //console.log(token);

  //       if (token) {
  //         headers.append("Authorization", `Bearer ${token}`);
  //       }

  //       if (editingDevice) {
  //         // Update existing device
  //         await putRequest({
  //           url: `${process.env.REACT_APP_SERVER_URL}devicewhitelisting/edit`,
  //           thunkApi: null,
  //           data: { ...deviceData },
  //           headers: headers,
  //         });
  //       } else {
  //         // Create new device
  //         await postRequest({
  //           url: `${process.env.REACT_APP_SERVER_URL}devicewhitelisting/add`,
  //           thunkApi: null,
  //           data: deviceData,
  //         });
  //       }

  //       setLoading(false);
  //       onCloseDialog(); // Close dialog on success
  //     } catch (e) {
  //       console.log(e);
  //       setError("Failed to save device. Please try again."); // Set error message
  //       setLoading(false);
  //     }
  //   };

  // Create or update device based on editingDevice
  const saveDevice = async (deviceData: Device) => {
    try {
      setLoading(true);
      setError(null);

      const headers: HeadersInit = new Headers();
      headers.append("Content-Type", "application/json");

      if (token) {
        headers.append("Authorization", `Bearer ${token}`);
      }

      const url = editingDevice
        ? `${process.env.REACT_APP_SERVER_URL}devicewhitelisting/edit`
        : `${process.env.REACT_APP_SERVER_URL}devicewhitelisting/add`;

      const method = editingDevice ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(deviceData),
      });

      if (!response.ok) {
        throw new Error("Failed to save device.");
      }

      setLoading(false);
      onCloseDialog();
    } catch (e) {
      console.log(e);
      setError("Failed to save device. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleBodyScroll = () => {
      if (showDialog) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    };

    handleBodyScroll();

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [showDialog]);

  const initialValues = {
    id: editingDevice ? editingDevice.id : "",
    deviceBrand: editingDevice ? editingDevice.deviceBrand : "",
    deviceModel: editingDevice ? editingDevice.deviceModel : "",
    isActive: editingDevice
      ? editingDevice.isActive
        ? "true"
        : "false"
      : "true",
  };

  return (
    <div
      id="myModal"
      className={`modal-overlay ${showDialog ? "show" : "hide"}`}
    >
      <div className="modal-content">
        <Formik
          initialValues={initialValues}
          validationSchema={DeviceSchema}
          onSubmit={(values, { setSubmitting }) => {
            const deviceData = {
              ...values,
              isActive: values.isActive === "true",
              source: "Portal",
              lastModified: new Date(),
            };
            saveDevice(deviceData);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="modal-header">
                <button type="button" className="close" onClick={onCloseDialog}>
                  &times;
                </button>
                <h2>{editingDevice ? "Edit Device" : "New Device"}</h2>
              </div>

              <div className="modal-body">
                {error && <div className="error-message">{error}</div>}{" "}
                {/* Display error message */}
                <div className="form-group">
                  <label htmlFor="deviceBrand">Device Brand</label>
                  <Select
                    id="deviceBrand"
                    options={deviceBrandOptions}
                    value={deviceBrandOptions.find(
                      (option) => option.value === values.deviceBrand
                    )}
                    onChange={(option) =>
                      setFieldValue("deviceBrand", option?.value || "")
                    }
                    isClearable
                    isSearchable
                    placeholder="Select or type a device brand"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="deviceBrand"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="deviceModel">Device Model</label>
                  <Field
                    name="deviceModel"
                    type="text"
                    id="deviceModel"
                    className="form-control"
                    placeholder="Enter device model"
                  />
                  <ErrorMessage
                    name="deviceModel"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="isActive">Active Status</label>
                  <Field
                    as="select"
                    name="isActive"
                    id="isActive"
                    className="form-select"
                  >
                    <option value="false">Inactive</option>
                    <option value="true">Active</option>
                  </Field>
                  <ErrorMessage
                    name="isActive"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting || loading}
                >
                  {loading
                    ? "Saving..."
                    : editingDevice
                    ? "Save Changes"
                    : "Create Device"}
                </button>
                <button
                  type="button"
                  onClick={onCloseDialog}
                  className="btn btn-secondary"
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DeviceWhitelistingModal;
